import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/hgw/projects/transbooks/transbooks-site/src/templates/podcast-post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`ゲストは 2017 年の TRANS BOOKS に参加いただいていたアーティストでプラクティショナー・コレクティヴであるコ本や honkbooks主宰する青柳菜摘 (だつお) さんです！リアル書店を運営する先輩でもある青柳さんから、書店の今や古本の面白さについて伺いました。TRANS BOOKS DOWNLOADs のリアル進出のためのオンライン作戦会議も。`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      